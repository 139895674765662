<template>
    <CoachingModeMarble class="fixed scale-150 md:scale-125 opacity-30 -z-10" :class="[positionClass]" />
</template>

<script setup>
import { computed } from "vue";

import CoachingModeMarble from "./navigation/CoachingModeMarble.vue";

const { position } = defineProps({
    position: { type: String, default: "bottom" },
});

const positionClass = computed(() => {
    switch (position) {
        case "top":
            return "-translate-y-1/2 top-0 bottom-auto";
        case "bottom":
            return "translate-y-1/2 bottom-0 top-auto";
        case "middle":
        default:
            return "top-1/2 -translate-y-1/2";
    }
});
</script>

<style scoped></style>
